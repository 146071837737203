<template>
  <div>
    <div v-if="loading !== true && clientCountrySeries.length > 0">
      <apexchart
          :options="clientCountryChartOptions"
          ref="clientCountryChart"
          :series="clientCountrySeries"
      ></apexchart>
    </div>
    <div v-else-if="clientCountrySeries.length <= 0" style="height:200px">
      <b-alert variant="warning" style="margin-top: 100px" class="p-1" show>
        <feather-icon
            class="mx-1"
            icon="AlertTriangleIcon"
            size="22"
        />
        <span class="bold">{{ $t('globalMessages.noDataFound') }}</span>
      </b-alert>
    </div>
    <div v-else>
      <b-skeleton-img style="height: 300px"></b-skeleton-img>
    </div>
  </div>
</template>
<script>
import { vue } from '@/main';

export default {
  name: 'ClientCountryPieChart',
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    chartData: {
      type: Object,
      default: () => ({})
    }
  },
  watch:{
    chartData(){
      if(this.$refs['clientCountryChart'] != null)
        this.$refs['clientCountryChart'].refresh();
    }
  },
  computed:{

    countryMap() {
      let countryList = vue.$t('countryList'); // Assuming countryList is an array of country objects
      return countryList.reduce((map, country) => {
        map[country.code] = country.name; // Use countryCode as the key and name as the value
        return map;
      }, {});
    },
    clientCountryChartOptions() {
      let total = Object.values(this.chartData).reduce((sum, count) => sum + count, 0); // Total count of clients

      // Create an array of countries with their count and percentage
      let countryData = Object.keys(this.chartData).map(countryCode => {
        let count = this.chartData[countryCode];
        let percentage = ((count / total) * 100).toFixed(2); // Calculate percentage
        return {
          countryCode: countryCode,
          countryName: this.countryMap[countryCode] || 'Unknown', // Use countryMap to get country name
          count: count,
          percentage: percentage
        };
      });

      // Sort by count (highest to lowest)
      countryData.sort((a, b) => b.count - a.count);

      // Map the sorted data to labels
      let labels = countryData.map(country => `${country.countryName}: ${country.count} (${country.percentage}%)`);

      return {
        chart: {
          type: 'pie',
        },
        legend: {
          customClass: 'apexcharts-legend-text2',
          position: 'left',
          horizontalAlign: 'center',
          floating: false,
          fontWeight: 800,
          itemMargin: {
            horizontal: 10,
            vertical: 5,
          },
          fontSize: '14px',
          formatter: function (label, opts) {
            return label; // Format the legend items if needed
          },
        },
        labels: labels, // Use the sorted labels
        title: {
          text: 'Client Country Chart',
          align: 'middle',
          style: {
            fontSize:  '14px',
            fontWeight:  'bold',
            fontFamily:  undefined,
            color:  '#1c9bda'
          },
        },
      };
    },

    clientCountrySeries() {
      let total = Object.values(this.chartData).reduce((sum, count) => sum + count, 0); // Total count of clients

      // Create an array of countries with their count
      let countryData = Object.keys(this.chartData).map(countryCode => {
        let count = this.chartData[countryCode];
        return {
          countryCode: countryCode,
          count: count
        };
      });

      // Sort by count (highest to lowest)
      countryData.sort((a, b) => b.count - a.count);

      // Return only the sorted counts (for the series)
      return countryData.map(country => country.count);
    }

  }
}
</script>



<style scoped>
.dark-layout .apexcharts-legend-text {
  background-color: rgba(255, 255, 255, 0);
  color: white !important;
}

.apexcharts-legend-text2 {
  background-color: rgba(208, 106, 106, 0);
  color: #ff0000 !important;
}
</style>
