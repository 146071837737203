<template>
  <b-card no-body class="m-0">
    <b-card-body class="d-flex justify-content-between align-items-center p-1">
      <i class="fas fa-info-circle" style="color:#efd179;font-size: 15px;position: absolute;right: 7px;top:7px"
         v-if="description != null" v-b-tooltip v-bind:title="description"
      ></i>
      <div class="truncate">
        <div class="text-primary font-weight-bold" style="font-size: 17px;margin-bottom: 5px">{{ statisticTitle }}</div>
        <slot name="content">
          <h2 style="font-size: 20px!important;" class="mb-25 font-weight-bolder">
            <slot name="content-text">
              <b-skeleton v-if="loading"></b-skeleton>
              <span v-else>{{ statistic }}</span>
            </slot>
          </h2>
        </slot>
        <slot name="footer">
          <span class="text-muted" style="font-size: 12px">{{ statisticDescription }}</span>
        </slot>
      </div>
      <b-avatar
          :variant="`light-${color}`"
          size="45"
      >
        <feather-icon v-if="iconType === 'feather'" size="21" :icon="icon"/>
        <i v-if="iconType === 'fontawesome'" v-bind:class="{[icon]:true}" style="font-size: 21px;"
           v-bind:style="iconStyle"
        />
      </b-avatar>
    </b-card-body>

    <!-- Footer Section -->
    <template v-slot:footer>
      <div class="footer-section">
        <!-- Your footer content here -->
        <!-- Example footer content -->
        <div>
          {{ statisticFooter }}
        </div>
      </div>
    </template>
    <!-- End Footer Section -->
  </b-card>
</template>


<script>
import { BCard, BCardBody, BAvatar } from 'bootstrap-vue';

export default {
  components: {
    BCard,
    BCardBody,
    BAvatar,
  },
  props: {
    icon: {
      type: String,
      required: true,
    },
    iconType: {
      default: () => 'feather',
    },
    iconStyle: {},
    statistic: {
      type: [Number, String],
    },
    statisticTitle: {
      type: String,
      default: '',
    },
    statisticFooter: {
      type: String,
      default: '',
    },
    statisticDescription: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'primary',
    },
    description: {},
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
