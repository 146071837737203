<template>
  <div>
    <div v-if="loading !== true">
      <apexchart width="100%" :height="calculatedHeight" type="bar"
                 :options="countryChartOptions"
                 ref="countryChart"
                 :series="countrySeries"
      ></apexchart>
    </div>
    <div v-else>
      <b-skeleton-img style="height: 200px"></b-skeleton-img>
    </div>
  </div>
</template>

<script>
import { vue } from '@/main';

export default {
  name: 'CountryDepositsBarChart',
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    chartData: {
      type: Object,
      default: () => ({})
    },
    currencyFormat: {
      type: Boolean,
      default: true
    }
  },
  watch:{
    chartData(){
      if(this.$refs['countryChart'] != null)
        this.$refs['countryChart'].refresh();
    }
  },
  computed:{
    countryMap() {
      let countryList = vue.$t('countryList'); // Assuming countryList is an array of country objects
      return countryList.reduce((map, country) => {
        map[country.code] = country.name; // Use countryCode as the key and name as the value
        return map;
      }, {});
    },
    calculatedHeight() {
      // Calculate height based on the number of items in the series
      const dataCount = this.countrySeries.length > 0 ? this.countrySeries[0].data.length : 0;
      return Math.max(400, dataCount * 40); // Adjust the multiplier (40) as per your design
    },
    countrySeries(){

      let data = Object.values(this.chartData)
          .map(value => parseFloat(value).toFixed(2));
      return [
        {
          name: 'Country Chart',
          data,
        },
      ]
    },
    countryChartOptions(){
      let $this = this;
      let categories = Object.keys(this.chartData).map(countryCode => {
        return this.countryMap[countryCode] || 'Unknown';
      });

      console.log('categories', categories);
      return {
        chart: {
          type: 'bar',
          height: 700,
        },
        grid: {
          show: false, // Hide grid lines
        },
        plotOptions: {
          bar: {
            borderRadius: 0,
            borderRadiusApplication: 'around',
            borderRadiusWhenStacked: 'last',
            columnWidth: '70%',
            barHeight: '75%',
            distributed: true,
            rangeBarOverlap: true,
            rangeBarGroupRows: false,
            hideZeroBarsWhenGrouped: false,
            isDumbbell: false,
            dumbbellColors: undefined,
            isFunnel: true,
            isFunnel3d: true,
            horizontal: true,
            dataLabels: {
              position: 'right',
            },
          },
        },
        dataLabels: {
          style: { colors: ['#000000'] },

          enabled: true,
          formatter: function (val, opt) {
            if (!$this.currencyFormat) {
              return new Intl.NumberFormat('de-AT', {
                style: 'currency',
                currency: 'EUR',
              }).format(val);
            } else {
              return '#' + val;
            }
          },
          dropShadow: {
            enabled: true,
          },
        },
        title: {
          text: 'Country Chart',
          align: 'middle',
          style: {
            fontSize:  '14px',
            fontWeight:  'bold',
            fontFamily:  undefined,
            color:  '#1c9bda'
          },
        },
        yaxis: {
          labels: {
            style: {
              colors: '#2b7194',
              fontSize:  '14px',
              fontWeight:  'bold',
              fontFamily:  undefined,

            }
          }
        },
        xaxis: {
          categories,
          labels: {
            style: {
              colors: '#2b7194'
            }
          }
        },
        legend: {
          show:false,
          position: 'right',
          horizontalAlign: 'center'
        },
      }
    }
  }
}
</script>



<style scoped>

</style>
