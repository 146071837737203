<template>
  <div class="mb-2">
    <div class="text-center">
      <div class="d-inline-block px-1" :style="`width: ${buttonWidth}`" v-for="option in options" :key="option">
        <b-button
            block
            :variant="selected !== option ? 'primary' : 'outline-primary'"
            size="sm"
            @click="onOptionSelected(option)"
            class="text-nowrap overflow-hidden d-flex align-items-center justify-content-center"
        >
          <span class="d-inline-block text-center">{{ $t(`filters.picker.${option}`) }}</span>
        </b-button>
      </div>
      <div id="calendar-bar-custom-range" class="d-inline-block px-1" :style="`width: ${buttonWidth}`">
        <b-button block
                  :variant="selected !== 'custom' ? 'primary' : 'outline-primary'"
                 :class="{ 'outline-primary': selected !== 'custom' }" size="sm"
                  class="text-nowrap overflow-hidden d-flex align-items-center justify-content-center"
        >
          <span class="d-inline-block text-center">{{ $t('filters.picker.custom') }}</span>
        </b-button>
      </div>
      <div v-if="selected==='custom'" style="position: absolute; right: 45px;">
        {{ customRange.start | moment($t('timeFormat.date.format')) }} -
        {{ customRange.end | moment($t('timeFormat.date.format')) }}
      </div>

      <b-popover target="calendar-bar-custom-range" title="Choose dates" placement="bottomleft">
        <calendar-picker-range v-model="customRange" @input="onOptionSelected('custom')"/>
      </b-popover>
    </div>
  </div>
</template>


<script>
import CalendarPickerRange from '@/components/widget/picker/calendarPickerRange';
import mixinPopover from '@/components/mixin/mixinPopover';

export default {
  name: 'CalendarBar',
  mixins: [mixinPopover],
  components: { CalendarPickerRange },
  data: () => ({
    options: [
      'all', 'today', 'yesterday', 'thisWeek', 'lastWeek', 'last7Days', 'thisMonth', 'lastMonth', 'last30Days', 'thisYear'
    ],
    selected: 'all',
    customRange: {
      start: '2022-01-01',
      end: '2024-01-01'
    },
  }),
  props: {
    value: {
      default: null
    },
  },
  created() {
    this.onOptionSelected(this.selected);
  },
  computed: {
    buttonWidth() {
      return `${Math.floor(100 / (this.options.length + 1))}%`;
    }
  },
  methods: {
    onOptionSelected(option) {
      this.selected = option;
      this.closeAllPopovers();
      this.$emit('input', this.getValue(option));
    },
    getValue(option) {
      switch (option) {
        case 'all':
          return {
            from: this.$moment()
                .subtract(30, 'years')
                .format('YYYY-MM-DD'),
            until: this.$moment()
                .format('YYYY-MM-DD')
          };
        case 'today':
          return {
            from: this.$moment()
                .format('YYYY-MM-DD'),
            until: this.$moment()
                .format('YYYY-MM-DD')
          };
        case 'yesterday':
          return {
            from: this.$moment()
                .subtract(1, 'days')
                .format('YYYY-MM-DD'),
            until: this.$moment()
                .subtract(1, 'days')
                .format('YYYY-MM-DD')
          };
        case 'thisWeek':
          return {
            from: this.$moment()
                .startOf('week')
                .format('YYYY-MM-DD'),
            until: this.$moment()
                .endOf('week')
                .format('YYYY-MM-DD')
          };
        case 'lastWeek':
          return {
            from: this.$moment()
                .subtract(1, 'week')
                .startOf('week')
                .format('YYYY-MM-DD'),
            until: this.$moment()
                .subtract(1, 'week')
                .endOf('week')
                .format('YYYY-MM-DD')
          };
        case 'last7Days':
          return {
            from: this.$moment()
                .subtract(7, 'days')
                .format('YYYY-MM-DD'),
            until: this.$moment()
                .format('YYYY-MM-DD')
          };
        case 'thisMonth':
          return {
            from: this.$moment()
                .startOf('month')
                .format('YYYY-MM-DD'),
            until: this.$moment()
                .endOf('month')
                .format('YYYY-MM-DD')
          };
        case 'lastMonth':
          return {
            from: this.$moment()
                .subtract(1, 'month')
                .startOf('month')
                .format('YYYY-MM-DD'),
            until: this.$moment()
                .subtract(1, 'month')
                .endOf('month')
                .format('YYYY-MM-DD')
          };
        case 'last30Days':
          return {
            from: this.$moment()
                .subtract(30, 'days')
                .format('YYYY-MM-DD'),
            until: this.$moment()
                .format('YYYY-MM-DD')
          };
        case 'thisYear':
          return {
            from: this.$moment()
                .startOf('year')
                .format('YYYY-MM-DD'),
            until: this.$moment()
                .endOf('year')
                .format('YYYY-MM-DD')
          };
        case 'custom':
          return {
            from: this.customRange.start,
            until: this.customRange.end
          };
      }
    },
  }
};
</script>

<style scoped>

</style>
