<template>
  <div>
    <div v-if="loading !== true">
      <apexchart width="100%" :height="calculatedHeight" type="bar"
                 :options="userChartOptions"
                 ref="userChart"
                 :series="userSeries"
      ></apexchart>
    </div>
    <div v-else>
      <b-skeleton-img style="height: 200px"></b-skeleton-img>
    </div>
  </div>
</template>


<script>
export default {
  name: 'UserSalesBarChart',
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    chartData: {
      type: Object,
      default: () => ({})
    },
    currencyFormat: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({}),
  watch: {
    chartData() {
      if (this.$refs['userChart'] != null) {
        this.$refs['userChart'].refresh();
      }
    }
  },
  computed: {
    calculatedHeight() {
      // Calculate height based on the number of items in the series
      const dataCount = this.userSeries.length > 0 ? this.userSeries[0].data.length : 0;
      return Math.max(400, dataCount * 40); // Adjust the multiplier (40) as per your design
    },
    userSeries() {
      let data = Object.values(this.chartData)
          .map(value => parseFloat(value)
              .toFixed(2));
      return [{
        name: 'User Chart',
        data,
      }];
    },

    userChartOptions() {
      let $this = this;
      let categories = Object.keys(this.chartData);
      return {
        chart: {
          type: 'bar',
        },
        grid: {
          show: false, // Hide grid lines
        },
        theme: {
          mode: 'light',
          palette: 'palette1',
          monochrome: {
            enabled: false,
            color: '#255aee',
            shadeTo: 'light',
            shadeIntensity: 0.65
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 0,
            borderRadiusApplication: 'around',
            borderRadiusWhenStacked: 'last',
            columnWidth: '10%',
            barHeight: '75%',
            distributed: false,
            rangeBarOverlap: true,
            rangeBarGroupRows: false,
            hideZeroBarsWhenGrouped: false,
            isDumbbell: false,
            dumbbellColors: undefined,
            isFunnel: false,
            isFunnel3d: true,
            horizontal: true,
            dataLabels: {
              position: 'right'
            },
          }
        },

        dataLabels: {
          style: { colors: ['#000000'] },
          position: 'top',
          enabled: true,
          formatter: function (val, opt) {
            if (!$this.currencyFormat) {
              return new Intl.NumberFormat('de-AT', {
                style: 'currency',
                currency: 'EUR',
              }).format(val);
            } else {
              return '#' + val;
            }
          },
          dropShadow: {
            enabled: true,
          },
        },
        title: {
          text: 'User Chart',
          align: 'middle',
          style: {
            fontSize:  '14px',
            fontWeight:  'bold',
            fontFamily:  undefined,
            color:  '#1c9bda'
          },
        },

        xaxis: {
          categories,
          labels: {
            style: {
              colors: '#2b7194'
            }
          }
        },

        yaxis: {
          labels: {
            style: {
              colors: '#2b7194',
              fontSize:  '14px',
              fontWeight:  'bold',
              fontFamily:  undefined,

            }
          }
        },
        legend: {
          show:false,
          position: 'right',
          horizontalAlign: 'center',
        },
      };
    }
  }
};
</script>

<style scoped>

</style>
