<template>
  <div>
    <div v-if="loading !== true && statusSeries.length > 0">
      <apexchart
          :options="statusChartOptions"
          ref="statusChart"
          :series="statusSeries"
      ></apexchart>
    </div>
    <div v-else-if="statusSeries.length <= 0" style="height:200px">
      <b-alert variant="warning" style="margin-top: 100px" class="p-1" show>
        <feather-icon
            class="mx-1"
            icon="AlertTriangleIcon"
            size="22"
        />
        <span class="bold">{{ $t('globalMessages.noDataFound') }}</span>
      </b-alert>
    </div>
    <div v-else>
      <b-skeleton-img style="height: 200px"></b-skeleton-img>
    </div>
  </div>
</template>


<script>
import { vue } from '@/main';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'ClientStatusPieChart',
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    chartData: {
      type: Object,
      default: () => ({})
    }
  },
  watch: {
    chartData() {
      if (this.$refs['statusChart'] != null) {
        this.$refs['statusChart'].refresh();
      }
    }
  },
  async created() {

    await this.refreshData();

  },
  computed: {

    ...mapGetters('clients', ['allStatuses']),

    statusChartOptions() {
      let total = Object.values(this.chartData)
          .reduce((sum, count) => sum + count, 0);

      let statusData = Object.keys(this.chartData)
          .map(statusId => {
            let count = this.chartData[statusId];
            let percentage = ((count / total) * 100).toFixed(2);
            return {
              statusId: statusId,
              name: this.statusMap[statusId].name,
              color: this.statusMap[statusId].color,
              count: count,
              percentage: percentage
            };
          });

      statusData.sort((a, b) => b.count - a.count);

      let labels = statusData.map(status => `${status.name}: ${status.count} (${status.percentage}%)`);
      let colors = statusData.map(status => status.color);

      return {
        chart: {

          type: 'pie',
        },
        colors: colors,
        labels: labels,
        legend: {
          position: 'left',
          horizontalAlign: 'center',
          floating: false,
          fontWeight: 800,
          itemMargin: {
            horizontal: 10,
            vertical: 5,
          },
          fontSize: '14px',
          formatter: function (label, opts) {
            return label; // Format the legend items if needed
          },
        },



        title: {
          text: 'Status Chart',
          align: 'middle',
          style: {
            fontSize:  '14px',
            fontWeight:  'bold',
            fontFamily:  undefined,
            color:  '#1c9bda'
          },

        },
      };
    },
    statusMap() {
      return this.allStatuses.reduce((map, status) => {
        map[status.id] = {
          name: status.status,
          color: status.color
        };
        return map;
      }, {});
    },
    statusSeries() {
      // Calculate the total count from chartData
      let total = Object.values(this.chartData).reduce((sum, count) => sum + count, 0);

      // Create an array of statuses with their count
      let statusData = Object.keys(this.chartData)
          .map(statusId => {
            let count = this.chartData[statusId];
            let percentage = ((count / total) * 100).toFixed(2);
            return {
              statusId: statusId,
              count: count,
              percentage: percentage,
              name: this.statusMap[statusId].name,
              color: this.statusMap[statusId].color
            };
          });

      // Sort the statuses by count (highest to lowest)
      statusData.sort((a, b) => b.count - a.count);

      // Extract and return the sorted series (count values)
      return statusData.map(status => status.count);
    }

  },
  methods: {
    ...mapActions('clients', ['refreshData']),

  }
};
</script>


<style scoped>
</style>
